<template>
    <div class="section">
        <div class="section__content">
            <div class="section__name">
                <p v-if="check_change_name" @click="clickChangeName">{{section.name}}</p>
                <input v-focus type="text" v-model="section.name" @keyup.enter="changeName" @blur="changeName" @keyup.esc="removeSection" v-else>
                <span v-if="!section.new" @click="removeSectionPopup">
                    <img :src="require(`@/assets/static/images/remove-section.svg`)" alt="">
                </span>
            </div>
        </div>
        <div class="section__tasks" v-if="show_tasks" :style="tasks.length == 0 ? 'padding-left: 11px;padding-right: 11px;' : ''">
            <div class="section__tasks_no" v-if="tasks.length == 0">
                Drag & drop tasks here
            </div>
            <div class="draggable_tasks" v-else>
                <div class="draggable_tasks_group">
                    <Tasks :tasks="tasks"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    const focus = {
        inserted(el) {
            el.focus()
        },
    }

    import { mapActions, mapGetters, mapMutations } from "vuex"
    import {EventEmitter} from '../../main'

    import Tasks from "./Tasks"

    export default {
        name: 'Section',
        directives: { focus },
        props: {
            section: {
                type: Object,
                required: true,
            },
            tasks: {
                type: Array
            },
            chat_id: [String],
            project_id: [String]
        },
        data() {
            return {
                drag: false,
                check_change_name: true,
                show_tasks: true,
            }
        },
        mounted() {

            if (this.section.new) {
                this.check_change_name = false
                this.show_tasks = false
                // setTimeout(() => {
                //     this.focusOnEditButton()
                // }, 100)
            }
            
            EventEmitter.$on('removeThisSection', (data) => {
                if (data.section_id == this.section._id) {
                    this.removeSection(data)
                }
            })

        },
        computed:{
            ...mapGetters([
                "get_flag_for_tasks"
            ])
        },
        methods: {
            ...mapActions([
                "socket_change_order",
                "socket_completed_task",
                "remove_section_this_user"
            ]),
            ...mapMutations([
                "remove_section",
                "change_task_section"
            ]),
            focusOnEditButton() {
                this.$nextTick(() => {
                    document.querySelector('.section__name input').focus()
                })
            },
            clickChangeName() {
                this.check_change_name = false
                setTimeout(() => document.querySelector('.section .section__name input').focus(), 500)
            },
            removeSection(data) {
                if (this.section.new) {
                    this.remove_section({
                        project_id: this.project_id,
                        section_id: this.section._id
                    })
                } else {
                    this.showActionBar = false
                    this.remove_section_this_user()
                    this.remove_section({
                        project_id: this.project_id,
                        section_id: this.section._id,
                        remove_tasks: data.remove_tasks
                    })
                    this.change_task_section({
                        project_id: this.project_id,
                        section_id: this.section._id,
                        remove_tasks: data.remove_tasks
                    })
                    this.$socket.emit('IN_RemoveSection', {
                        project_id: this.project_id,
                        section_id: this.section._id,
                        remove_tasks: data.remove_tasks
                    })
                }
            },
            removeSectionPopup() {
                let data = {
                    type: 'section',
                    data: {
                        text: 'Do you want to delete this section?',
                        cancel: 'Cancel',
                        delete: 'Delete'
                    },
                    list: this.section._id
                }
                EventEmitter.$emit('OpenPopup', data)
                // this.$w_alert('Do you want to delete this section?', [
                //     {
                //         buttonText: 'Cancel',
                //         callback: (close) => {
                //             close()
                //         }
                //     },
                //     {
                //         buttonText: 'Delete',
                //         className: 'red',
                //         callback: (close) => {
                //             this.removeSection()
                //             close()
                //         }
                //     }
                // ])
            },
            changeName() {
                if (this.section.new) {
                    if (this.section.name != '') {
                        this.section.name = this.section.name.slice(0, 160)
                        this.$socket.emit('IN_AddSection', {
                            project_id: this.project_id,
                            section_id: this.section._id,
                            section_name: this.section.name,
                            section_order: this.section.order
                        })
                        this.section.new = false
                        this.show_tasks = true
                        this.check_change_name = !this.check_change_name
                    } else {
                        this.removeSection()
                    }
                } else {
                    if (this.section.name != '') {
                        this.section.name = this.section.name.slice(0, 160)
                        this.$socket.emit('IN_RenameSection', {
                            project_id: this.project_id,
                            section_id: this.section._id,
                            section_name: this.section.name
                        })
                        this.check_change_name = !this.check_change_name
                    }
                }
            }
        },
        components: {
            Tasks
        }
    }
</script>