<template>
  <ul>
    <li v-for="task of tasks" :key="task._id">
      <Task :task="task" :type="type" :move="move"></Task>
    </li>
  </ul>
</template>

<script>

  export default {
    name: 'TasksComponent',
    props: ['tasks', 'type', 'move'],
    components: {
      Task: () => import('@/components/tasks/Task')
    }
  }

</script>
