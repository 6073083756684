<template>
  <div class="top-list__top-name" v-if="list">
    <p v-if="!show_input" @click="clickListName">{{ list.name }}</p>
    <input v-else type="text" v-model="list.name" @keyup.enter.prevent="changeListName" @blur="changeListName">
  </div>
</template>

<script>

  import { mapActions } from "vuex"

  export default {
    data: () => ({
      show_input: false
    }),
    computed: {
      list() {
        return this.$store.state.projects.projects.find(e => e._id == this.$route.params.project_id)
      }
    },
    methods: {
      ...mapActions(["socket_rename_project"]),
      changeListName() {
        this.list.name = this.list.name.replace(/[\r\n\v]+/g, "")
        this.list.name = this.list.name.slice(0, 160)
        if (this.list.name) {
          // this.show_input = !this.show_input
          this.socket_rename_project({
            name: this.list.name,
            _id: this.list._id
          })
        }
      },
      clickListName() {
        this.show_input = !this.show_input
        setTimeout(() => {
          document.querySelector('.top-list__top-name input').focus()
        }, 50)
      }
    }
  }

</script>