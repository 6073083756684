<template>
  <div class="top-list__top-item" @click="muteChange">
    <div v-html="mute.svg"></div>
    <p>{{ mute.text }}</p>
  </div>
</template>

<script>

  import {EventEmitter} from "../../main"

  export default {
    name: 'TaskMute',
    data: () => ({
      mute: {
        bool: false,
        svg: `
          <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.166656 11.1667V6.83334C0.166656 6.54602 0.280793 6.27047 0.483957 6.06731C0.687122 5.86414 0.962672 5.75001 1.24999 5.75001H4.17174C4.38573 5.75005 4.59495 5.68672 4.77299 5.56801L9.31541 2.53901C9.47871 2.43005 9.66855 2.36752 9.86464 2.35809C10.0607 2.34867 10.2557 2.3927 10.4287 2.48549C10.6017 2.57827 10.7462 2.71633 10.8469 2.88489C10.9475 3.05345 11.0004 3.24619 11 3.44251V14.5575C11 14.7536 10.9468 14.9461 10.846 15.1143C10.7452 15.2826 10.6007 15.4203 10.4277 15.5129C10.2548 15.6054 10.06 15.6492 9.8641 15.6397C9.6682 15.6302 9.47856 15.5677 9.31541 15.4588L4.77299 12.4309C4.59483 12.3126 4.38562 12.2496 4.17174 12.25H1.24999C0.962672 12.25 0.687122 12.1359 0.483957 11.9327C0.280793 11.7295 0.166656 11.454 0.166656 11.1667V11.1667Z" stroke="#000" stroke-width="0.9"/>
            <path d="M15.875 4.125C15.875 4.125 17.5 5.75 17.5 8.45833C17.5 11.1667 15.875 12.7917 15.875 12.7917" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.125 0.875C19.125 0.875 21.8333 3.58333 21.8333 8.45833C21.8333 13.3333 19.125 16.0417 19.125 16.0417" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        text: "Mute"
      }
    }),
    methods: {
      setCurrentMuteStatus() {
        if (this.storageAvailable('localStorage') && window.localStorage.getItem('mutedObjects')) {
          let mutedObjects = window.localStorage.getItem('mutedObjects').split(',')
          if (mutedObjects.includes(this.$route.params.project_id)) {
            this.mute.bool = true
            this.mute.text = 'Unmute'
            this.mute.svg = `
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5833 8.00001L20.75 10.1667M16.4167 10.1667L18.5833 8.00001L16.4167 10.1667ZM20.75 5.83334L18.5833 8.00001L20.75 5.83334ZM18.5833 8.00001L16.4167 5.83334L18.5833 8.00001Z" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.25 10.1667V5.83334C1.25 5.54602 1.36414 5.27047 1.5673 5.06731C1.77047 4.86414 2.04602 4.75001 2.33333 4.75001H5.25508C5.46908 4.75005 5.67829 4.68672 5.85633 4.56801L10.3988 1.53901C10.5621 1.43005 10.7519 1.36752 10.948 1.35809C11.1441 1.34867 11.339 1.3927 11.512 1.48549C11.685 1.57827 11.8296 1.71633 11.9302 1.88489C12.0308 2.05345 12.0838 2.24619 12.0833 2.44251V13.5575C12.0834 13.7536 12.0301 13.9461 11.9294 14.1143C11.8286 14.2826 11.684 14.4203 11.5111 14.5129C11.3381 14.6054 11.1433 14.6492 10.9474 14.6397C10.7515 14.6302 10.5619 14.5677 10.3988 14.4588L5.85633 11.4309C5.67817 11.3126 5.46897 11.2496 5.25508 11.25H2.33333C2.04602 11.25 1.77047 11.1359 1.5673 10.9327C1.36414 10.7295 1.25 10.454 1.25 10.1667Z" stroke="#000" stroke-width="0.9"/>
              </svg>
            `;
          } else {
            this.mute.bool = false
            this.mute.text = 'Mute'
            this.mute.svg = `
              <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.166656 11.1667V6.83334C0.166656 6.54602 0.280793 6.27047 0.483957 6.06731C0.687122 5.86414 0.962672 5.75001 1.24999 5.75001H4.17174C4.38573 5.75005 4.59495 5.68672 4.77299 5.56801L9.31541 2.53901C9.47871 2.43005 9.66855 2.36752 9.86464 2.35809C10.0607 2.34867 10.2557 2.3927 10.4287 2.48549C10.6017 2.57827 10.7462 2.71633 10.8469 2.88489C10.9475 3.05345 11.0004 3.24619 11 3.44251V14.5575C11 14.7536 10.9468 14.9461 10.846 15.1143C10.7452 15.2826 10.6007 15.4203 10.4277 15.5129C10.2548 15.6054 10.06 15.6492 9.8641 15.6397C9.6682 15.6302 9.47856 15.5677 9.31541 15.4588L4.77299 12.4309C4.59483 12.3126 4.38562 12.2496 4.17174 12.25H1.24999C0.962672 12.25 0.687122 12.1359 0.483957 11.9327C0.280793 11.7295 0.166656 11.454 0.166656 11.1667V11.1667Z" stroke="#000" stroke-width="0.9"/>
                <path d="M15.875 4.125C15.875 4.125 17.5 5.75 17.5 8.45833C17.5 11.1667 15.875 12.7917 15.875 12.7917" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.125 0.875C19.125 0.875 21.8333 3.58333 21.8333 8.45833C21.8333 13.3333 19.125 16.0417 19.125 16.0417" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            `;
          }
        }
      },
      changeMuteStatus(status) {
        if (status === false || status === true) {
          this.$socket.emit("IN_MutedProject", {
            project_id: this.$route.params.project_id,
            muted: (status) ? true : false,
          });
        }
      },
      muteChange() {
        if (!this.mute.bool) {
          this.changeMuteStatus(true)
          this.mute = {
            bool: true,
            svg: `
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.5833 8.00001L20.75 10.1667M16.4167 10.1667L18.5833 8.00001L16.4167 10.1667ZM20.75 5.83334L18.5833 8.00001L20.75 5.83334ZM18.5833 8.00001L16.4167 5.83334L18.5833 8.00001Z" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.25 10.1667V5.83334C1.25 5.54602 1.36414 5.27047 1.5673 5.06731C1.77047 4.86414 2.04602 4.75001 2.33333 4.75001H5.25508C5.46908 4.75005 5.67829 4.68672 5.85633 4.56801L10.3988 1.53901C10.5621 1.43005 10.7519 1.36752 10.948 1.35809C11.1441 1.34867 11.339 1.3927 11.512 1.48549C11.685 1.57827 11.8296 1.71633 11.9302 1.88489C12.0308 2.05345 12.0838 2.24619 12.0833 2.44251V13.5575C12.0834 13.7536 12.0301 13.9461 11.9294 14.1143C11.8286 14.2826 11.684 14.4203 11.5111 14.5129C11.3381 14.6054 11.1433 14.6492 10.9474 14.6397C10.7515 14.6302 10.5619 14.5677 10.3988 14.4588L5.85633 11.4309C5.67817 11.3126 5.46897 11.2496 5.25508 11.25H2.33333C2.04602 11.25 1.77047 11.1359 1.5673 10.9327C1.36414 10.7295 1.25 10.454 1.25 10.1667Z" stroke="#000" stroke-width="0.9"/>
              </svg>
            `,
            text: "Unmute"
          }
        } else {
          this.changeMuteStatus(false)
          this.mute = {
            bool: false,
            svg: `
              <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.166656 11.1667V6.83334C0.166656 6.54602 0.280793 6.27047 0.483957 6.06731C0.687122 5.86414 0.962672 5.75001 1.24999 5.75001H4.17174C4.38573 5.75005 4.59495 5.68672 4.77299 5.56801L9.31541 2.53901C9.47871 2.43005 9.66855 2.36752 9.86464 2.35809C10.0607 2.34867 10.2557 2.3927 10.4287 2.48549C10.6017 2.57827 10.7462 2.71633 10.8469 2.88489C10.9475 3.05345 11.0004 3.24619 11 3.44251V14.5575C11 14.7536 10.9468 14.9461 10.846 15.1143C10.7452 15.2826 10.6007 15.4203 10.4277 15.5129C10.2548 15.6054 10.06 15.6492 9.8641 15.6397C9.6682 15.6302 9.47856 15.5677 9.31541 15.4588L4.77299 12.4309C4.59483 12.3126 4.38562 12.2496 4.17174 12.25H1.24999C0.962672 12.25 0.687122 12.1359 0.483957 11.9327C0.280793 11.7295 0.166656 11.454 0.166656 11.1667V11.1667Z" stroke="#000" stroke-width="0.9"/>
                  <path d="M15.875 4.125C15.875 4.125 17.5 5.75 17.5 8.45833C17.5 11.1667 15.875 12.7917 15.875 12.7917" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.125 0.875C19.125 0.875 21.8333 3.58333 21.8333 8.45833C21.8333 13.3333 19.125 16.0417 19.125 16.0417" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            `,
            text: "Mute"
          }
        }
      },
      storageAvailable(type) {
        let storage
        try {
          storage = window[type]
          let x = "__storage_test__"
          storage.setItem(x, x)
          storage.removeItem(x)
          return true
        } catch (e) {
          return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
          )
        }
      }
    },
    mounted() {
      setTimeout(() => {
        this.setCurrentMuteStatus()
      }, 100)
      EventEmitter.$on('ProjectMuted', (data) => {
        if (data.project_id == this.project_id) {
          if (data.muted) {
            this.mute = {
              bool: true,
              svg: `
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.5833 8.00001L20.75 10.1667M16.4167 10.1667L18.5833 8.00001L16.4167 10.1667ZM20.75 5.83334L18.5833 8.00001L20.75 5.83334ZM18.5833 8.00001L16.4167 5.83334L18.5833 8.00001Z" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.25 10.1667V5.83334C1.25 5.54602 1.36414 5.27047 1.5673 5.06731C1.77047 4.86414 2.04602 4.75001 2.33333 4.75001H5.25508C5.46908 4.75005 5.67829 4.68672 5.85633 4.56801L10.3988 1.53901C10.5621 1.43005 10.7519 1.36752 10.948 1.35809C11.1441 1.34867 11.339 1.3927 11.512 1.48549C11.685 1.57827 11.8296 1.71633 11.9302 1.88489C12.0308 2.05345 12.0838 2.24619 12.0833 2.44251V13.5575C12.0834 13.7536 12.0301 13.9461 11.9294 14.1143C11.8286 14.2826 11.684 14.4203 11.5111 14.5129C11.3381 14.6054 11.1433 14.6492 10.9474 14.6397C10.7515 14.6302 10.5619 14.5677 10.3988 14.4588L5.85633 11.4309C5.67817 11.3126 5.46897 11.2496 5.25508 11.25H2.33333C2.04602 11.25 1.77047 11.1359 1.5673 10.9327C1.36414 10.7295 1.25 10.454 1.25 10.1667Z" stroke="#000" stroke-width="0.9"/>
                </svg>
              `,
              text: "Unmute"
            }
          } else {
            this.mute = {
              bool: false,
              svg: `
                <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.166656 11.1667V6.83334C0.166656 6.54602 0.280793 6.27047 0.483957 6.06731C0.687122 5.86414 0.962672 5.75001 1.24999 5.75001H4.17174C4.38573 5.75005 4.59495 5.68672 4.77299 5.56801L9.31541 2.53901C9.47871 2.43005 9.66855 2.36752 9.86464 2.35809C10.0607 2.34867 10.2557 2.3927 10.4287 2.48549C10.6017 2.57827 10.7462 2.71633 10.8469 2.88489C10.9475 3.05345 11.0004 3.24619 11 3.44251V14.5575C11 14.7536 10.9468 14.9461 10.846 15.1143C10.7452 15.2826 10.6007 15.4203 10.4277 15.5129C10.2548 15.6054 10.06 15.6492 9.8641 15.6397C9.6682 15.6302 9.47856 15.5677 9.31541 15.4588L4.77299 12.4309C4.59483 12.3126 4.38562 12.2496 4.17174 12.25H1.24999C0.962672 12.25 0.687122 12.1359 0.483957 11.9327C0.280793 11.7295 0.166656 11.454 0.166656 11.1667V11.1667Z" stroke="#000" stroke-width="0.9"/>
                  <path d="M15.875 4.125C15.875 4.125 17.5 5.75 17.5 8.45833C17.5 11.1667 15.875 12.7917 15.875 12.7917" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.125 0.875C19.125 0.875 21.8333 3.58333 21.8333 8.45833C21.8333 13.3333 19.125 16.0417 19.125 16.0417" stroke="#000" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              `,
              text: "Mute"
            }
          }
        }
      })
    }
  }

</script>