<template>
  <div style="height: 100vh;">
    <div v-if="!$route.params.task_id" style="height: 100svh;">
      <Header @openLeftNavbar="openLeftNavbar" @showActivityLog="show_activity_log = !show_activity_log"
        @search="search" />
      <LeftNavbar :open="open_left_navbar" />
      <ActivityLog :show="show_activity_log" @showActivityLog="show_activity_log = !show_activity_log"></ActivityLog>
      <section id="top-list-sec" class="top-list-section">
        <template v-if="!background_images">
          <template v-if="background_image_url_state">
            <img class="bg_image" :src="background_image_url_state">
          </template>
          <template v-else>
            <img class="bg_image" :src="require('@/assets/static/images/bg/' + background_image)">
          </template>
          <div style="
            background-color: #DEE8F2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
          "></div>
        </template>
        <div class="container-fluid p-0">
          <div class="top-list" v-if="!search_value"
            @touchend="blockTouchEnd" @touchmove="blockScroll">

            <div class="top-list__top" v-if="!$route.meta.shortcut && user.shortcut_inbox != $route.params.project_id">
              <ListRename></ListRename>
              <div class="top-list__top-right">
                <TaskMute></TaskMute>
                <TaskShare></TaskShare>
                <TaskSort></TaskSort>
              </div>
            </div>
            <div class="top-list__top" v-else>
              <div class="top-list__top-name">
                <p v-if="$route.name == 'Inbox'">
                  Inbox
                </p>
                <p v-else>
                  {{ $route.params.shortcuts[0].toUpperCase() }}{{ $route.params.shortcuts
                    .replaceAll('_', ' ').replace($route.params.shortcuts[0], '') }}
                </p>
              </div>
              <TaskSort v-if="$route.name == 'Inbox'"></TaskSort>
            </div>

            <TaskAdd></TaskAdd>
            <!-- <Tasks :tasks="tasks" type="tasks"></Tasks> -->
            <ul>
              <li v-for="task of tasks" :key="task._id">
                <Task :task="task" type="tasks" :move="move"></Task>
              </li>
            </ul>

            <div class="draggable_tasks" v-if="sections.length">
              <div class="draggable_tasks_group">
                <Section v-for="section in sections" :key="section._id" :section="section"
                  :tasks="tasks.filter(e => e.section == section._id).sort((a, b) => a.order - b.order)" :chat_id="''"
                  :project_id="$route.params.project_id"></Section>
              </div>
            </div>

            <button v-if="!$route.params.shortcuts" @click="addSection" class="task_button">Add Section</button>
            <button v-if="!$route.params.shortcuts && completed_tasks.length > 0"
              @click="show_completed_task = !show_completed_task" class="task_button">
              {{ show_completed_task ? `Hide completed tasks (${completed_tasks_count})` : `Show completed tasks
              (${completed_tasks_count})` }}
            </button>

            <div class="completed_tasks" v-if="show_completed_task">
              <Tasks :tasks="completed_tasks" :move="move"></Tasks>
            </div>

          </div>
          <div class="top-list" v-else>
            <div v-for="list of search_data" :key="list._id" class="search-task">
              <h2>{{ list.name }}</h2>
              <Tasks :tasks="list.tasks"></Tasks>
            </div>
            <div v-if="search_data.length == 0" class="search_no_result">
              <p>Sorry, no results found</p>
             
            </div>
          </div>
        </div>
      </section>
    </div>
    <transition name="nested">
      <router-view v-if="$route.params.task_id"></router-view>
    </transition>
    <Menu :bool="search_value ? true : false"></Menu>
    <DrawerContextMenu />
    <div class="top-list-section__bg-opacity" @click="openLeftNavbar"></div>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex"

import Header from '@/components/app/Header'
import LeftNavbar from '@/components/app/LeftNavbar'
import ActivityLog from '@/components/history/ActivityLog'

import ListRename from '@/components/lists/ListRename'
import Section from '@/components/tasks/Section'

import Tasks from '@/components/tasks/Tasks'
import Task from '@/components/tasks/Task'
import TaskAdd from '@/components/tasks/TaskAdd'
import TaskMute from '@/components/tasks/TaskMute'
import TaskShare from '@/components/tasks/TaskShare'
import TaskSort from '@/components/tasks/TaskSort'
import Menu from '@/components/tasks/Menu'
import DrawerContextMenu from '@/components/lists/Menu'

export default {
  name: "Project",
  data: () => ({
    open_left_navbar: false,
    show_activity_log: false,
    show_completed_task: false,
    search_value: '',
    search_data: [],
    move: false,
    touchTime: ''
  }),
  methods: {
    ...mapMutations([
      "setMessage",
      "add_section"
    ]),
    openLeftNavbar(bool) {
      if (typeof bool == 'boolean') {
        this.open_left_navbar = bool
      } else {
        this.open_left_navbar = !this.open_left_navbar
      }
      if (this.open_left_navbar) {
        document.querySelector('#app').classList.add('menu-open')
      } else {
        document.querySelector('#app').classList.remove('menu-open')
        this.$root.$emit('closeLeftBar')
      }
    },
    search(value) {
      this.search_data = []
      this.$store.state.projects.projects.forEach(list => {

        let tasks = []

        if (this.$store.state.tasks.tasks[list._id]) {
          tasks = tasks.concat(this.$store.state.tasks.tasks[list._id].filter(e => {
            return e.name.toLowerCase().includes(value.toLowerCase())
          }))
        }

        if (this.$store.state.tasks.completed_tasks[list._id]) {
          tasks = tasks.concat(this.$store.state.tasks.completed_tasks[list._id].filter(e => {
            return e.name.toLowerCase().includes(value.toLowerCase())
          }))
        }

        if (tasks.length > 0) {
          this.search_data.push({
            _id: list._id,
            name: list.name,
            tasks: tasks
          })
        }

      })
      this.search_value = value
    },
    addSection() {

      let w_bool = false

      this.$w_plan(this, this.getPlan, 'section', () => {
        this.setMessage({
          key_message: "warning",
          message: "You can't add more sections to this list. ",
          plan: true
        })
        w_bool = true
      }, this.$route.params.project_id)

      if (w_bool) return

      let order = this.sections.length == 0 ? 0 : this.sections[this.sections.length - 1].order + 1

      this.add_section({
        section_id: this.$w_decrypt(this.getUserData._id),
        section_name: '',
        section_order: order,
        project_id: this.$route.params.project_id,
        new: true
      })

    },
    blockScroll() {
      this.move = true
    },
    blockTouchEnd() {
      this.move = false
    }
  },
  watch: {
    $route(e) {
      this.open_left_navbar = false
      document.querySelector('#app').classList.remove('menu-open')
      this.$root.$emit('closeLeftBar')
      this.search_data = []
      this.search_value = ''
      if (e.params.task_id) {
        document.head.querySelector("[name~=theme-color][content]").content = '#fff'
      } else {
        setTimeout(() => {
          document.head.querySelector("[name~=theme-color][content]").content = '#c78495'
        }, 500)
      }
    }
  },
  unmounted() {
    document.head.querySelector("[name~=theme-color][content]").content = '#fff'
  },
  mounted() {
    document.head.querySelector("[name~=theme-color][content]").content = '#E6E6E6'
  },
  computed: {
    ...mapGetters([
      "getTasks",
      "getProjects",
      "getComments",
      "getCompletedTasks",
      "get_flag_for_tasks",
      "getSubTask",
      "getUserData",
      "getPlan"
    ]),
    user() {
      return this.$store.state.key.user
    },
    sections() {
      let list = this.$store.state.projects.projects.find(e => e._id == this.$route.params.project_id)
      if (list) {
        return list.sections ? list.sections.sort((a, b) => a.order - b.order) : []
      } else return []
    },
    lists() {
      return this.$store.state.projects.projects
    },
    tasks() {
      if (this.$route.params.shortcuts) {
        let tasks = []
        this.lists.forEach((list) => {
          let state_tasks = this.$store.state.tasks.tasks[list._id] ? this.$store.state.tasks.tasks[list._id] : []
          if (this.$route.params.shortcuts == 'today') {
            tasks = tasks.concat(state_tasks.filter(task => this.$w_date_parse(task.assign.date) == 'Today'))
          }
          if (this.$route.params.shortcuts == 'assigned_to_me') {
            tasks = tasks.concat(state_tasks.filter(task => task.assign.user_id == this.user._id))
          }
          if (this.$route.params.shortcuts == 'medium_priority') {
            tasks = tasks.concat(state_tasks.filter(task => task.priority == 'Medium'))
          }
          if (this.$route.params.shortcuts == 'high_priority') {
            tasks = tasks.concat(state_tasks.filter(task => task.priority == 'High'))
          }
        })
        return tasks
      } else {
        let tasks = this.$store.state.tasks.tasks[this.$route.params.project_id] || []
        return tasks.sort((a, b) => a.order - b.order)
      }
    },
    completed_tasks() {
      let tasks = this.$store.state.tasks.completed_tasks[this.$route.params.project_id] || []
      if (tasks.length == 0) this.show_completed_task = false
      return tasks.filter(e => e.parent == '').sort((a, b) => {
        if (!b.hasOwnProperty('completed_date')) {
          b.completed_date = "0"
        }
        if (!a.hasOwnProperty('completed_date')) {
          a.completed_date = "0"
        }
        if (b.hasOwnProperty('completed_date') && a.hasOwnProperty('completed_date')) {
          return b.completed_date - a.completed_date
        }
      })
    },
    completed_tasks_count() {
      let tasks = this.$store.state.tasks.completed_tasks[this.$route.params.project_id] || []
      return tasks.length
    },
    background_image() {
      return this.$store.state.key.background_image
    },
    background_images() {
      let image = this.$store.state.key.background_images.find(e => e.image_name == this.$store.state.key.background_image)
      if (image) {
        return image.color ? image.color : false
      } else {
        return false
      }
    },
    background_image_url_state() {
      return this.$store.state.key.background_image_url
    },
  },
  components: {
    Header,
    LeftNavbar,
    Tasks,
    TaskAdd,
    TaskMute,
    TaskShare,
    TaskSort,
    ListRename,
    ActivityLog,
    Section,
    Menu,
    Task,
    DrawerContextMenu
  }
}

</script>
