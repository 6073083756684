<template>
    <div class="top-list__add" v-if="list">
        <input 
            type="text"
            class="tasks_row"
            v-model="task_title"
            :placeholder="`Add a task in '${list.name}'`"
            @keyup.enter="addTask"
            ref="add_task"
            maxlength="160"
        >
        <span>{{ task_title_length }}</span>
    </div>
</template>

<script>

    import { mapActions, mapGetters } from "vuex"
    import {EventEmitter} from "../../main"

    export default {
        data() {
            return {
                task_title: "",
                project_id: this.$route.params.project_id,
                project_name: "",
                task_title_length: 160
            }
        },
        methods: {
            ...mapActions(["add_task", "socket_add_task", "socket_change_order"]),
            addTask() {
                if (this.task_title.trim() != "") {

                    this.task_title = this.task_title.trim().slice(0, 160)

                    const tasks = []

                    if (this.getTasks[this.project_id]) {
                        this.getTasks[this.project_id].forEach((e) => {
                            if(e) {
                                if (!e.parent && !e.section) {
                                    tasks.push(e)
                                }
                            }
                        })
                    }

                    tasks.sort( function (a, b) {
                        return a.order - b.order
                    })

                    let order = []
                    let body = ''

                    if (this.getUserData.new_task == true) {
                        body = {
                            name: this.task_title.trim(),
                            project_id: this.project_id,
                            order: 0,
                            _id: this.$w_decrypt(this.getUserData._id),
                            assign: {
                                user_id: false,
                                date: ''
                            }
                        }
                    }

                    if (this.getUserData.new_task == false) {

                        if (tasks) {
                            tasks.forEach((e) => {
                                order.push(Number(e.order))
                            })
                        } else {
                            order = 0
                        }

                        body = {
                            name: this.task_title.trim(),
                            project_id: this.project_id,
                            order: order == 0 ? 0 : Math.max.apply(null, order) + 1,
                            _id: this.$w_decrypt(this.getUserData._id),
                            assign: {
                                user_id: false,
                                date: ''
                            }
                        }

                    }

                    setTimeout(() => {
                        EventEmitter.$emit('changeTaskLength', {
                            project_id: this.project_id
                        })
                        this.socket_add_task(body)
                    }, 0)

                    this.task_title = ""
                }
            }
        },
        computed: {
            ...mapGetters(["getTasks", "getUserData", "get_flag_for_add_task"]),
            list() {
                return this.$store.state.projects.projects.find(e => e._id == this.project_id)
            }
        },
        watch: {
            get_flag_for_add_task(e) {
                if (!e) {
                    setTimeout(() => {
                        this.$refs.add_task.focus()
                    }, 0)
                }
            },
            $route(to) {
                this.task_title = ''
                this.project_id = this.$route.params.project_id
            },
            task_title(e) {
                this.task_title_length = 160 - this.task_title.length
                if (e.length > 160) {
                    this.task_title = e.slice(0, 160)
                }
            }
        }
    }

</script>
