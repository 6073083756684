<template>
    <div class="context-menu" :class="{ active: show }">
        <div class="context-menu__content">
            <ul class="context-menu__lists">
                <li v-if="bool" @click="contextMenu('mark_as_read')">
                    <span>Mark as read</span>
                </li>
                <li v-if="data" @click="contextMenu('rename')">
                    <span>Rename</span>
                </li>
                <li v-if="!author" @click="contextMenu('delete')">
                    <span>Delete</span>
                </li>
                <li v-if="author" @click="contextMenu('leave')">
                    <span>Leave</span>
                </li>
            </ul>
            <button class="context-menu__button" @click="closeContextMenu">Close</button>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from "vuex";

import { EventEmitter } from "../../main"

export default {
    data() {
        return {
            data: '',
            bool: '',
            author: false,
            show: false,
            mark_read: false,
            type: '',
        }
    },
    computed: {
        ...mapGetters([
            "getUserData",
            "getLists",
            "getPlan",
            "get_unreaded_messages_in_project",
        ]),
    },
    mounted() {
        this.$root.$on('listContextMenu_open', (position_menu) => {
            this.bool = position_menu.bool
            this.data = position_menu.data
            this.show = position_menu.show
            this.type = position_menu.type

            if (this.data.date) {
                this.mark_read = this.get_unreaded_messages_in_project[this.data._id] > 0 || true
            }
            if (position_menu.data.author) {
                this.author = this.getUserData._id == position_menu.data.author ? false : true
            }
            setTimeout(() => {
                if (position_menu.bool == true) {
                    let list = document.querySelectorAll('.listContextMenu__text')
                    if (list.length == 2) {
                        list.forEach((e, i) => {
                            if (e.textContent == 'Delete') {
                                e.remove()
                            }
                        })
                    }
                }
            }, 1);
        })

    },
    methods: {
        ...mapActions([
            "add_folder_render_lists",
            "socket_delete_project",
            "remove_folder"
        ]),
        ...mapMutations([
            "setChangeUserData",
            "setMessage"
        ]),
        closeContextMenu() {
            this.show = false
            this.$root.$emit('listContextMenu_close', true)
        },
        contextMenu(action) {
            if (action == 'add_folder') {

                let w_bool = false

                this.$w_plan(this, this.getPlan, 'folder', () => {
                    this.setMessage({
                        key_message: "warning",
                        message: "You can't add more folders. ",
                        plan: true
                    })
                    w_bool = true
                })

                if (w_bool) {
                    return
                }

                this.setChangeUserData({
                    type: 'add_folder',
                    field: {
                        _id: this.$w_decrypt(this.getUserData._id),
                        name: '',
                        lists: [],
                        order: this.getLists['folder'].length > 0 ? this.getLists['folder'].length : 0
                    }
                })
                setTimeout(() => {
                    this.add_folder_render_lists()
                }, 100);
                setTimeout(() => {
                    document.querySelector('.folder__flex input').focus()
                }, 500);
            } else if (action == 'delete') {
                if (this.type === 'list') {
                    let data = {
                        type: 'list',
                        list: this.data,
                        bool: this.bool,
                        data: {
                            text: 'Do you want to delete this list?',
                            cancel: 'Cancel',
                            delete: 'Delete'
                        }
                    }
                    EventEmitter.$emit('OpenPopup', data)
                } else if (this.type === 'folder') {
                    let data = {
                        type: 'folder',
                        folder: this.data,
                        data: {
                            text: 'Do you want to delete this folder?',
                            cancel: 'Cancel',
                            delete: 'Delete'
                        }
                    }
                    EventEmitter.$emit('OpenPopup', data)
                } else if (this.type === 'chat') {
                    let data = {
                        type: 'chat_delete',
                        chat: this.data,
                        data: {
                            text: `Do you want to delete this chat?`,
                            cancel: 'Cancel',
                            delete: "Delete"
                        }
                    }
                    EventEmitter.$emit('OpenPopup', data)
                }
            } else if (action == 'leave') {
                let data;
                if (this.type === 'chat') {
                    data = {
                        type: 'chat_leave',
                        chat: this.data,
                        data: {
                            text: 'Do you want to leave this chat?',
                            cancel: 'Cancel',
                            delete: 'Leave'
                        }
                    }
                } else {
                    data = {
                        type: 'leave',
                        list: this.data,
                        data: {
                            text: 'Do you want to leave this list?',
                            cancel: 'Cancel',
                            delete: 'Leave'
                        }
                    }
                }

                EventEmitter.$emit('OpenPopup', data)

            } else if (action == 'rename') {
                if (this.type === 'list') {
                    this.$root.$emit('renameList', this.data)
                } else if (this.type === 'folder') {
                    this.$root.$emit('renameFolder', this.data)
                } else if (this.type === 'chat') {
                    const chatId = this.data._id;
                    this.$root.$emit('RenameChatFromContext', chatId);
                }
            } else if (action == 'mark_as_read') {
                this.$socket.emit('IN_ListMakeIsRead', { list_id: this.data._id })
            }
            this.closeContextMenu();
        }
    }
}

</script>

<style lang="scss">
.context-menu {
    position: fixed;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    transition: background-color ease-in-out 0.5s, top 0.25s ease-in-out .5s;
    z-index: 99;
    display: flex;
    align-items: flex-end;
}

.context-menu.active {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.35);
    transition: top ease-in-out .5s, background-color 0.25s ease-in-out 0.5s;
}

.context-menu__content {
    width: 100%;

    @supports (-webkit-touch-callout: none) {
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.context-menu__content ul {
    padding: 14px;
    margin: 0;
    width: 100%;
    list-style: none;
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 15px;
    max-height: 80vh;
    overflow: auto;
}

.context-menu__content li {
    font-weight: 400;
    color: #464646;
    width: 100%;
    height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 25px;

    &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}

.context-menu__button {

    font-weight: 400;
    color: #464646;
    width: calc(100% - 28px);
    height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 14px;
    margin-top: 0;
    border: 0;
    outline: none;
}
</style>